.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.messages{
 margin-top: 250px; /* Default for PC */
   padding-bottom: 100px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-content1{
    padding-bottom: 100px;
     /* margin-top: 2200px; */
     position: absolute;
     top: 20px;
       left: 0;
       right: 0;
}
.user-agreement-content{
   /* margin-top: 600px;  */
   padding-bottom: 100px;
    position: absolute;
     top: 20px;
       left: 0;
       right: 0;
}
.discovery{
  position: absolute;
  top: 0px;
       left: 0px;
       right: 0;
}
/* Main content styles */
.main-content {
  /* margin-top: 500px;  */
  margin-top: 40px;
   padding-bottom: 100px;
    position: absolute;
     top: 20px;
       left: 0;
       right: 0;
}
.discovery{
  margin-top: 60px;
}
 .contactus{
    margin-top: 40px;
     position: absolute;
     top: 10px;
       left: 0;
       right: 0;
  }
/* Adjust main content margin for mobile devices */
/* @media (max-width: 868px) {
  .main-content {
    margin-top: 1200px; 
   
  }
  .main-content1{
    padding-bottom: 100px;
     margin-top: 1000px;
}
.user-agreement-content{
   margin-top: 900px; 
   padding-bottom: 100px;
}
}
@media (max-width: 480px) {
  .contactus{
    margin-top: 50px;
  }
  .discovery{
  margin-top: 80px;
}

 .main-content {
    margin-top: 1300px;
  }
  .main-content1{
    padding-bottom: 100px;
     margin-top: 5500px;
}
.user-agreement-content{
   margin-top:1800px;
   padding-bottom: 100px;
   padding-top: 100px;
}
.messages{
 margin-top: 200px; 
   padding-bottom: 100px;


}

} */


