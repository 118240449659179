/* SearchIcon.css */
.search-container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;
}

.search-box {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1000;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
}

.search-box.show {
    transform: translateX(0);
    opacity: 1;
}

.search-input {
    z-index: 100;
    padding-left: 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    outline: none;
}

.search-icon {
    padding: 5px;
    cursor: pointer;
 
    color: white;
 
    border-radius: 0 4px 4px 0;
}
