.shared-component {

  margin-top: 40px;


  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  text-align: center;
}

.custom-carousel-img {
  border-radius: 8px;
}

.feature-list {
  margin-top: 20px;
  margin-bottom: 80px;
  text-align: left;
}

.feature-item {
  background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-button {

  color: black;
  border: none;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-button:hover {
  background-color: #0056b3;
  transform: translateX(5px);
}

.feature-item h3 {
  color: #007bff;
  margin-bottom: 5px;
}

.feature-item h4 {
  margin: 10px 0 5px;
  font-weight: bold;
}

.feature-item p {
  margin: 0;
  color: #333;
  height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.2em;
}

.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: white;
  border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.scroll-to-top:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
}

.feature-item::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s, opacity 1s;
}

.feature-item:active::after {
  transform: translate(-50%, -50%) scale(3);
  opacity: 0;
  transition: 0s;
} 