/* .header {
    background: #007bff;
    color: #fff;
    text-align: center;
    padding: 0.5em 0; 
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1 {
    margin: 0;
}

.menu-icon {
    display: none;
    font-size: 2em;
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 0px;
    z-index: 1001;
}

.nav-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    transition: transform 0.3s ease;
}

.nav-links a {
    margin: 0 15px;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    transition: background 0.3s, transform 0.3s;
}

.nav-links a:hover {
    text-decoration: none;
    background: linear-gradient(45deg, #de6262, #ff6f61);
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .header {
        text-align: start;
        padding: 0.5em;
        position: fixed; 
        top: -10; 
        left: 0;
        width: 100%;
        z-index: 1000;
       
      
    }
.header h1 {
  margin-left: 35%;
    z-index: 200000;
}
    .menu-icon {
        display: block;
    }

    .nav-links {
        flex-direction: column;
        flex-wrap: nowrap;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 200px;
        background: #007bff;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        justify-content: flex-start;
        align-items: center;
        padding-top: 3em;
        z-index: 999;
    }

    .nav-links.open {
        padding-right: 30px;
        transform: translateX(0);
    }

    .nav-links a {
        margin: 10px 0;
        width: 100%; 
        text-align: center;
    }
} */
/* Header.css */
/* Header.css */
.header {
    background: #0a0909;
    color: #fff;
   
    text-align: center;
    padding: 0.5em 0; /* Reduce height */
    position: fixed; /* Make the header fixed to the top */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: top 0.3s ease; /* Smooth transition for hiding/showing */
}

.header.hidden {
    top: -100px; /* Move the header out of view */
}

.header.visible {
    top: 0; /* Reset the header to its original position */
}

.header h1 {
    margin: 0;
}

.menu-icon {
    display: none;
    font-size: 2em;
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 0px;
    z-index: 1001;
}

.nav-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: nowrap;
    overflow-x: auto;
    transition: transform 0.3s ease;
    white-space: nowrap;
}

.nav-links a {
    margin: 0 15px;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    transition: background 0.3s, transform 0.3s;
}

.nav-links a:hover {
    text-decoration: none;
    background: linear-gradient(45deg, #de6262, #ff6f61);
    transform: scale(1.1);
}

.icons {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-right: 10px;
}

.search-icon, .notification-icon, .theme-toggle {
    font-size: 1.5em;
    cursor: pointer;
    z-index: 2000;
}

.theme-toggle {
    margin-left: 10px;
}

/* Mobile Styles */
@media (max-width: 768px) {

    .header {
        text-align: start;
        padding: 0.5em;
        position: fixed; /* Ensure the header is fixed on mobile */
        top: 0; /* Ensure it is fixed to the top */
        left: 0;
        width: 100%;
        z-index: 1000;
        height: 50px;
    }

    .header h1 {
        margin-left: 10%;
        z-index: 200;
    }

    .menu-icon {
        display: block;
    }

    .nav-links {
        flex-direction: column;
        flex-wrap: nowrap;
        position: fixed; /* Ensure the nav is fixed */
        top: 0;
        left: 0;
        height: 100vh;
        width: 300px;
     
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        justify-content: flex-start;
        align-items: start;
        padding-top: 3em;
      
        z-index: 999;
    }

    .nav-links.open {
        padding-right: 30px;
        transform: translateX(0);
    }

    .nav-links a {
        margin: 10px 0;
        width: 100%; /* Ensure full width */
        text-align: center;
    }
}

@media(max-width: 380px){

    .menu-icon {
    display: block;
    font-size: 2em;
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 4px;
    z-index: 1001;
}
}

.header.light {
    background-color: #ffffff;
    color: #000000;
}

.header.dark {
    background-color: #0a0909;
    color: #ffffff;
}

.nav-links.light {
    background-color: #f0f0f0;
    color: #000000;
}

.nav-links.dark {
    background-color: #333333;
    color: #ffffff;
}

.nav-links a {
    color: inherit; /* Inherit color from parent */
    text-decoration: none;
    padding: 10px 20px;
    transition: background 0.3s, transform 0.3s;
}

.nav-links a:hover {
    text-decoration: none;
    background: linear-gradient(45deg, #de6262, #ff6f61);
    transform: scale(1.1);
}

/* Add any additional styles for light and dark themes */