/* Footer.css */
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 1em 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

/* Ensure content pushes footer to the bottom */
html, body {
    height: 100%;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
}

#root {
    flex: 1;
}

.mobile-footer {
    display: none;
}

/* Adjust footer for mobile devices */
@media (max-width: 750px) {
    .footer {
        height: 50px;
        padding: 0.5em 0; /* Reduce padding on mobile devices */
        display: flex;
        justify-content: center;
        background-color: white;
    }
    .mobile-footer .download-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


    .desktop-footer {
        display: none;
    }
    .mobile-footer {
        display: flex;
        width: 100%;
        font-size: small;
        justify-content: center;
        align-items: center;
        padding: 1em;
    }
    .download-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: linear-gradient(45deg, #ed1d0a, #765b5b);
        border-radius: 25px;
        color: white;
        padding: 0.75em 1.5em;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
    }
    .download-button span {
        font-size: 1.2em; /* Increase the font size for emphasis */
    }
    .highlight {
        color: #ffd700; /* Change the color for emphasis */
        font-size: 1.5em; /* Make it larger */
        font-weight: bold;
    }
}


.contact-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 80%;
    max-width: 400px;
    text-align: center;
}

.dialog-content h3 {
    margin-top: 0;
    color: #333;
    font-size: 1.5em;
    margin-bottom: 15px;
}

.dialog-content p {
    margin: 10px 0;
    cursor: pointer;
    color: #007bff;
    font-size: 1.1em;
    transition: color 0.3s;
}

.dialog-content p:hover {
    color: #0056b3;
}

.dialog-content button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.3s;
}

.dialog-content button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}
