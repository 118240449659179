.notifications-container {
    max-width: 600px;
    margin-top: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification-list {
    list-style-type: none;
    padding: 0;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
}

.notification-item:hover {
    background-color: #e9ecef;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.notification-title {
    color: #007bff;
}

.notification-timestamp {
    color: #6c757d;
}

.notification-content {
    margin: 5px 0 0;
    color: #333;
} 